import { motion } from "framer-motion";
import "./footer.scss";
import Stencil from "../animations/stencil/stencil";

const Footer: React.FC = () => {
    const animate = (i: number) => ({
        hidden: { opacity: 0, y: 15, x: 5 },
        visible: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
                delay: 0.25 + i,
                duration: 0.5,
            },
        },
    });

    return (
        <div id='footer' className='footer'>
            <div className='footer-content'>
                <motion.div
                    className='footer-row'
                    initial='hidden'
                    whileInView='visible'
                >
                    <motion.h2 variants={animate(0)}>
                        <span>15</span> YOUR NEXT MOVE
                    </motion.h2>
                    <div className='company'>
                        <motion.img
                            src='../images/ingleby.svg'
                            alt='ingleby'
                            variants={animate(0)}
                        />
                        <div className='agents'>
                            <motion.div
                                className='agent'
                                variants={animate(0.1)}
                            >
                                <p className='name'>Jake Halstead</p>
                                <p>020 07029 3610 | 07989 481012</p>
                                <p>j.halstead@inglebytrice.co.uk</p>
                            </motion.div>
                            <motion.div
                                className='agent'
                                variants={animate(0.15)}
                            >
                                <p className='name'>James Heyworth-Dunne</p>
                                <p>020 7029 3626 | 07779 327071</p>
                                <p>jhd@inglebytrice.co.uk</p>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    className='footer-row'
                    initial='hidden'
                    whileInView='visible'
                >
                    <motion.div  variants={animate(0.25)} className='map'>
                        <a
                            href='https://www.google.com/maps/place/15+Bishopsgate,+London+EC2N+3AR/@51.5148582,-0.0859996,17z/data=!3m1!4b1!4m6!3m5!1s0x48760352b591ae33:0xd4874f27b3ffb9e9!8m2!3d51.5148549!4d-0.0834193!16s%2Fg%2F1td9vlk0?entry=ttu'
                            target='_blank'
                        ><p>
                            VIEW IN GOOGLE MAPS</p></a>
                        <img src='../images/map.svg' />
                    </motion.div>
                    <div className='company'>
                        <motion.img
                            className='cbre'
                            src='../images/cbre.svg'
                            alt='ingleby'
                            variants={animate(0.2)}
                        />
                        <div className='agents'>
                            <motion.div
                                className='agent'
                                variants={animate(0.25)}
                            >
                                <p className='name'>Anna Biggin</p>
                                <p>020 7182 3352 | 07931 842687</p>
                                <p>anna.biggin@cbre.com</p>
                            </motion.div>
                            <motion.div
                                className='agent'
                                variants={animate(0.3)}
                            >
                                <p className='name'>David Wilson</p>
                                <p>020 7182 8160 | 07584 339242</p>
                                <p>david.t.wilson@cbre.com</p>
                            </motion.div>
                        </div>
                        <motion.p className='dis' variants={animate(0.2)}>
                            MISREPRESENTATION ACT 1967 <br></br>
                            CBRE and Ingleby Trice, their clients and any joint
                            agents, give notice that: (i) These particulars do
                            not form part of any offer or contract and must not
                            be relied upon as statements or representations of
                            fact. (ii) No person in the employment of the
                            agent(s) has any authority to make or give any
                            representation or warranty whatever in relation to
                            this property. (iii) Floor areas, measurements or
                            distances given are approximate. Unless otherwise
                            stated, any rents, or outgoings quoted are exclusive
                            of VAT. (iv) Any descriptions given of the property
                            cannot be taken to imply, it is in good repair, has
                            all necessary consents, is free of contamination, or
                            that the services and facilities are in working
                            order. Interested parties are advised to carry out
                            their own investigations as required. June 2024.
                            <br></br>
                            <br></br>
                            siren | 020 7478 8300 | sirendesign.co.uk
                        </motion.p>
                    </div>
                </motion.div>
            </div>
            <div className='stencil'>
                <Stencil />
            </div>
        </div>
    );
};

export default Footer;
